import React from 'react';
import PropTypes from 'prop-types';

import AsyncSelect from 'react-select/async';

import {
    FormLabel,
    FormDescription,
    FormError,
} from '/components/forms/components';

const loadOptions = async (query) => {
    try {
        const response = await fetch(`/api/cities/${query}`);
        const json = await response.json();

        return json.data;
    } catch (error) {
        return [];
    }
};

const LocationsField = ({ input, label, description, meta, required, placeholder }) => (
    <div className={meta.touched && meta.error ? "form-item form-item--error" : "form-item"}>
        <AsyncSelect
            {...input}
            className="react-select"
            classNamePrefix="react-select"
            cacheOptions
            defaultOptions
            isClearable={false}
            isMulti
            loadOptions={loadOptions}
            getOptionValue={option => option.id}
            getOptionLabel={option => `${option.postalCode} ${option.name}`}
            placeholder={placeholder}
        />
        {/* <FormLabel label={label} required={required} /> */}
        <FormDescription description={description} />
        {meta.touched && <FormError error={meta.error} />}
    </div>
);

/*
{...input}
                cacheOptions
                className="locations-field"
                classNamePrefix="locations-field"
                components={{
                    DropdownIndicator: DownChevron,
                }}
                defaultOptions
                getOptionLabel={option => `${option.value} ${option.label}`}
                loadingMessage={() => 'Bezig met zoeken..'}
                loadOptions={loadOptions}
                isClearable={false}
                isMulti
                noOptionsMessage={() => 'Geen gemeente gevonden.'}
                placeholder=""
 */

LocationsField.propTypes = {
    input: PropTypes.shape({}).isRequired,
    label: PropTypes.string.isRequired,
    description: PropTypes.string,
    meta: PropTypes.shape({
        touched: PropTypes.bool,
        error: PropTypes.string,
    }).isRequired,
    required: PropTypes.bool,
};

LocationsField.defaultProps = {
    description: '',
    required: false,
};

export { LocationsField };
