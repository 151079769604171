const intersection = (arrayOne, arrayTwo) => {
    return arrayOne.filter(xxx => arrayTwo.includes(xxx));
};

export const pruneData = (allCategories, allPriceClasses, allCities, allEstates, allGroups) => {
    let estates = allEstates;
    let categories = allCategories;
    let cities = allCities;
    let priceClasses = allPriceClasses;
    let groups = allGroups;

    /* no children pls */
    estates = estates.filter(estate => !estate.isChildEstate);

    if (window.estateOverview) {
        const { filters } = window.estateOverview;
        const {
            purposes,
            purposeStatuses,
            categories,
            subcategories,
            showEstates,
            showProjects,
            countries,
        } = filters;

        if (purposes.length) {
            estates = estates.filter((estate) => {
                return intersection(estate.purposes, purposes).length > 0;
            });
        }

        if (countries.length) {
            estates = estates.filter((estate) => {
                return intersection(estate.countries, countries).length > 0;
            });
        }

        if (purposeStatuses.length) {
            estates = estates.filter((estate) => {
                return intersection(estate.purposeStatuses, purposeStatuses).length > 0;
            });
        }

        if (categories.length) {
            estates = estates.filter((estate) => {
                return intersection(estate.categories, categories).length > 0;
            });
        }

        if (subcategories.length) {
            estates = estates.filter((estate) => {
                return intersection(estate.subcategories, subcategories).length > 0;
            });
        }

        estates = estates.filter((estate) => {
            return !estate.isProject === showEstates || estate.isProject === showProjects;
        });
    }

    categories = categories.filter((category) => {
        return Boolean(estates.find(estate => estate.categories[0] === category.id));
    });

    cities = cities.filter((city) => {
        return Boolean(estates.find(estate => estate.locations[0] === city.id));
    });

    priceClasses = priceClasses.filter((priceClass) => {
        return Boolean(estates.find(estate => estate.priceClasses[0] === priceClass.id));
    });

    groups = groups.filter((group) => {
        return Boolean(estates.find(estate => estate.purposes[0] === group.id));
    });

    return {
        priceClasses,
        allEstates: estates,
        categories,
        locations: cities,
        groups,
    };
};
