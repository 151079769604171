import React from 'react';
import ReactDOM from 'react-dom';

import { EstimateForm } from '/components/estimate-form/EstimateForm';

(() => {
    const estimateFormContainer = document.querySelector('[data-estimate-form-container]');

    if (estimateFormContainer) {
        ReactDOM.render(
            <EstimateForm />,
            estimateFormContainer,
        );
    }
})();
