import React from 'react';
import PropTypes from 'prop-types';

const FormLabel = ({ label, required }) => (
    <div className="form-label freeform-label">
        {label}
        {required && <span className="form-required">*</span>}
    </div>
);

FormLabel.propTypes = {
    label: PropTypes.string.isRequired,
    required: PropTypes.bool,
};

FormLabel.defaultProps = {
    required: false,
};

export { FormLabel };
