class EstateNavigationService {
    static setSearchUrl = (url) => {
        try {
            window.sessionStorage.setItem('estateSearchUrl', url);
        } catch (error) {
            // progressive
        }
    };

    static getSearchUrl = () => {
        return window.sessionStorage.getItem('estateSearchUrl');
    };

    static setSearchResults = (estates) => {
        try {
            EstateNavigationService.setSearchUrl(window.location.toString());
            window.sessionStorage.setItem('estateSearchResults', JSON.stringify(estates));
        } catch (error) {
            // do nothing, it's progressive
        }
    };

    static getSearchResults = () => {
        try {
            const properties = window.sessionStorage.getItem('estateSearchResults');

            if (properties) {
                return JSON.parse(properties);
            }

            return [];
        } catch (error) {
            return [];
        }
    };
}

export { EstateNavigationService };
