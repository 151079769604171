import React from 'react';
import PropTypes from 'prop-types';

const FormDescription = ({ description }) => {
    if (description === '') {
        return null;
    }

    return <div className="form-description">{description}</div>;
};

FormDescription.propTypes = {
    description: PropTypes.string,
};

FormDescription.defaultProps = {
    description: '',
};

export { FormDescription };
