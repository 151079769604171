import React from 'react';
import ReactDOM from 'react-dom';

import { EstateSearchForm } from '/components/estate-search-form/EstateSearchForm';

(() => {
    const estateSearchForm = document.querySelector('[data-estate-search-form]');

    if (estateSearchForm) {
        ReactDOM.render(
            <EstateSearchForm />,
            estateSearchForm,
        );
    }
})();
