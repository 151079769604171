import React from 'react';
import PropTypes from 'prop-types';

import {
    FormLabel,
    FormDescription,
    FormError,
} from '/components/forms/components';

const TextField = ({ input, label, description, meta, required, min, placeholder }) => (
    <div className={meta.touched && meta.error ? "form-item form-item--error" : "form-item"}>
        <input className="freeform-input" {...input} min={ min ? min : "" } placeholder={placeholder} />
        <FormLabel label={label} required={required} />
        <FormDescription description={description} />
        {meta.touched && <FormError error={meta.error} />}
    </div>
);

TextField.propTypes = {
    input: PropTypes.shape({}).isRequired,
    label: PropTypes.string.isRequired,
    description: PropTypes.string,
    meta: PropTypes.shape({
        touched: PropTypes.bool,
        error: PropTypes.string,
    }).isRequired,
    required: PropTypes.bool,
};

TextField.defaultProps = {
    description: '',
    required: false,
};

export { TextField };
