import React, { useState } from 'react';
import Validator from 'validatorjs';

import { Field, Form } from 'react-final-form';
import { CheckboxField, SelectField, TextField } from '/components/forms/fields';

import { ValidationService } from '/services';

const purposeOptions = [
    {"label":"te koop","value":"te koop"},
    {"label":"te huur","value":"te huur"},
];

const categoryOptions = [
    {"label":"appartement","value":"appartement"},
    {"label":"grond","value":"grond"},
    {"label":"kantoor","value":"kantoor"},
    {"label":"commercieel","value":"commercieel"},
    {"label":"industrieel","value":"industrieel"},
    {"label":"garage / parking","value":"garage / parking"},
    {"label":"huis","value":"huis"},
];

const EstimateForm = () => {
    const [error, setError] = useState(null);

    const validate = (values) => {
        const rules = {
            firstName: 'required',
            lastName: 'required',
            purpose: 'required',
            category: 'required',
            mobile: 'required',
            email: 'required',
            street: 'required',
            number: 'required',
            postalCode: 'required',
            city: 'required',
            privacy: 'required',
        };

        const names = {
            firstName: 'voornaam',
            lastName: 'achternaam',
            purpose: 'type transactie',
            category: 'type vastgoed',
            mobile: 'telefoon',
            email: 'e-mailadres',
            street: 'straat',
            number: 'nummer',
            box: 'bus',
            postalCode: 'postcode',
            city: 'gemeente',
        };

        return ValidationService.validate(values, rules, names);
    };

    const submit = async (values) => {
        const body = new FormData();
        body.append(window.csrfTokenName, window.csrfTokenValue);
        body.append('firstName', values.firstName);
        body.append('lastName', values.lastName);
        body.append('mobile', values.mobile);
        body.append('email', values.email);

        body.append('street', values.street);
        body.append('number', values.number);
        body.append('box', values.box);
        body.append('postalCode', values.postalCode);
        body.append('city', values.city);

        body.append('purpose', values.purpose && values.purpose.value);
        body.append('category', values.category && values.category.value);

        try {
            const response = await fetch('/index.php?p=actions/immodule/form/estimate', {
                method: 'post',
                body,
            });

            const bodyFromResponse = await response.json();

            if (bodyFromResponse.success) {
                window.location = window.successRedirect;
            } else {
                window.location = window.failureRedirect;
            }
        } catch {
            window.location = window.failureRedirect;
        }
    };

    const render = ({ handleSubmit }) => (
        <>
        { error && (
            <p className="estimate-form__error">{ error }</p>
        )}
        <form className="estimate-form" onSubmit={handleSubmit}>
            <div className="estimate-form-wrapper">
                <div className="estimate-form-rows">
                    <div className="freeform-rows">
                        <div className="freeform-row">
                            <div className="freeform-column">
                                <Field
                                    name="purpose"
                                    component={SelectField}
                                    label="Type transactie"
                                    placeholder="Type transactie*"
                                    options={purposeOptions}
                                    required
                                />
                            </div>
                            <div className="freeform-column">
                                <Field
                                    name="category"
                                    component={SelectField}
                                    label="Type vastgoed"
                                    placeholder="Type vastgoed*"
                                    options={categoryOptions}
                                    required
                                />
                            </div>
                        </div>
                        <div className="freeform-row">
                            <div className="freeform-column">
                                <Field
                                    name="firstName"
                                    component={TextField}
                                    label="Voornaam"
                                    type="text"
                                    placeholder="Voornaam"
                                    required
                                />
                            </div>
                            <div className="freeform-column">
                                <Field
                                    name="lastName"
                                    component={TextField}
                                    label="Naam"
                                    type="text"
                                    placeholder="Naam"
                                    required
                                />
                            </div>
                        </div>
                        <div className="freeform-row">
                            <div className="freeform-column">
                                <Field
                                    name="mobile"
                                    component={TextField}
                                    label="Telefoon"
                                    type="tel"
                                    placeholder="Telefoon"
                                    required
                                />
                            </div>
                            <div className="freeform-column">
                                <Field
                                    name="email"
                                    component={TextField}
                                    label="E-mail"
                                    type="email"
                                    placeholder="E-mail"
                                    required
                                />
                            </div>
                        </div>
                        <div className="freeform-row">
                            <div className="freeform-column">
                                <Field
                                    name="street"
                                    component={TextField}
                                    label="Straat"
                                    type="text"
                                    placeholder="Straat"
                                    required
                                />
                            </div>
                            <div className="freeform-column">
                                <div className="freeform-grid">
                                    <div className="freeform-column freeform-column--full">
                                        <Field
                                            name="number"
                                            component={TextField}
                                            label="Nr"
                                            type="number"
                                            placeholder="Nr"
                                            required
                                        />
                                    </div>
                                    <div className="freeform-column freeform-column--full">
                                        <Field
                                            name="box"
                                            component={TextField}
                                            label="Bus"
                                            placeholder="Bus"
                                            type="text"
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="freeform-row">
                            <div className="freeform-column">
                                <Field
                                    name="postalCode"
                                    component={TextField}
                                    label="Postcode"
                                    placeholder="Postcode"
                                    type="text"
                                    required
                                />
                            </div>
                            <div className="freeform-column">
                                <Field
                                    name="city"
                                    component={TextField}
                                    label="Gemeente"
                                    placeholder="Gemeente"
                                    type="text"
                                    required
                                />
                            </div>
                        </div>
                        <div className="freeform-row">
                            <div className="freeform-column">
                                <Field
                                    name="privacy"
                                    component={CheckboxField}
                                    label={window.privacyMessage}
                                    type="checkbox"
                                    required
                                />
                            </div>
                        </div>
                        <div className="freeform-row">
                            <div className="freeform-column">
                                <div className="freeform-button-wrapper">
                                    <button className="button" type="submit">
                                        Verzenden
                                        <span className="button-icon">
                                            <svg className="ml-1 w-5 h-5 fill-current" viewBox="0 0 12 21" xmlns="http://www.w3.org/2000/svg"><path d="m147.271262 17.6666667 6.681546 9.9802321-6.681546 10.0197679h4.873741l6.640735-10.0197679-6.640735-9.9802321z" fill-rule="evenodd" transform="translate(-147 -17)"></path></svg>
                                        </span>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </form>
        </>
    );

    return (
        <Form
            validate={validate}
            onSubmit={submit}
            render={render}
        />
    );
};

export { EstimateForm };
