import React from 'react';
import PropTypes from 'prop-types';

import {
    FormDescription,
    FormError,
} from '/components/forms/components';

const CheckboxField = ({ input, label, description, meta }) => (
    <div className={meta.touched && meta.error ? "form-item form-item--error" : "form-item"}>
        <label className="freeform-label freeform-input-only-label">
            <input className="freeform-input" {...input} />
            <div className="freeform-input-checkbox-text" dangerouslySetInnerHTML={{__html: label}} />
        </label>
        <FormDescription description={description} />
        {meta.touched && <FormError error={meta.error} />}
    </div>
);

CheckboxField.propTypes = {
    input: PropTypes.shape({}).isRequired,
    description: PropTypes.string,
    meta: PropTypes.shape({
        touched: PropTypes.bool,
        error: PropTypes.string,
    }).isRequired,
};

CheckboxField.defaultProps = {
    description: '',
    required: false,
};

export { CheckboxField };
