import React from 'react';
import PropTypes from 'prop-types';

import Select from 'react-select';

import {
    FormLabel,
    FormDescription,
    FormError,
} from '/components/forms/components';

const SelectField = ({ input, label, options, description, meta, required, placeholder }) => (
    <div className={meta.touched && meta.error ? "form-item form-item--error" : "form-item"}>
        <div className="form-item-select">
            <span class="form-item-select-icon-wrapper">
                <svg className="form-item-select-icon" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path d="m17 10-5 6-5-6z" /></svg>
            </span>
            <Select
                {...input}
                className="react-select"
                classNamePrefix="react-select"
                options={options}
                placeholder={placeholder}
            />
            {/* <FormLabel label={label} required={required} /> */}
        </div>
        <FormDescription description={description} />
        {meta.touched && <FormError error={meta.error} />}
    </div>
);

SelectField.propTypes = {
    input: PropTypes.shape({}).isRequired,
    label: PropTypes.string.isRequired,
    description: PropTypes.string,
    meta: PropTypes.shape({
        touched: PropTypes.bool,
        error: PropTypes.string,
    }).isRequired,
    required: PropTypes.bool,
};

SelectField.defaultProps = {
    description: '',
    required: false,
};

export { SelectField };
