import Validator from 'validatorjs';
import nl from 'validatorjs/src/lang/nl';

class ValidationService {
    static validate = (values, rules, names) => {
        Validator.setMessages('nl', nl);
        Validator.useLang('nl');

        const validator = new Validator(values, rules);

        validator.setAttributeNames(names);
        validator.check();
        return validator.errors.all();
    };
}

export { ValidationService };
