import * as actionTypes from "../actionTypes";

const initialState = {
    categories: [],
    locations: [],
    priceClasses: [],
    groups: [],
    sorting: (window.estateOverview && window.estateOverview.defaultSorting) ||  "DATE_DESCENDING",

    searchResults: [],
    searchTerm: "",
    search: false,
    surfaceMin: null,
    surfaceMax: null,
    minPrice: null,
    maxPrice: null,
    loading: false
};

export default (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.SET_FILTER_VALUE:
            return {
                ...state,
                [action.payload.filter]: action.payload.value,
            };
        case actionTypes.SET_LOADING:
            return {
                ...state,
                loading: true,
            };
        case actionTypes.SET_SEARCH_TERM:
            return {
                ...state,
                searchTerm: action.payload.searchTerm,
            };
        case actionTypes.SEARCH:
            return {
                ...state,
                loading: false,
                search: true,
                searchResults: action.payload.results.map(estate => estate.id),
            };
        case actionTypes.RESET_SEARCH:
            return {
                ...state,
                search: false,
                searchTerm: "",
                searchResults: []
            };
        case actionTypes.SET_SORTING:
            return {
                ...state,
                sorting: action.payload.sorting,
            };
        case actionTypes.ADD_FILTER_VALUE:
            return {
                ...state,
                [action.payload.filter]: state[action.payload.filter].concat(action.payload.value)
            };
        case actionTypes.REMOVE_FILTER_VALUE:
            return {
                ...state,
                [action.payload.filter]: state[action.payload.filter].filter( item => `${item}` !== `${action.payload.value}` )
            };
        default:
            return state;
    }
};
