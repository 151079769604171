export const INITIALIZE_DATA = 'INITIALIZE_DATA';
export const SET_SURFACE_MAX = 'SET_SURFACE_MAX';
export const SET_SURFACE_MIN = 'SET_SURFACE_MIN';
export const SET_FILTER_VALUE = 'SET_FILTER_VALUE';
export const ADD_FILTER_VALUE = 'ADD_FILTER_VALUE';
export const REMOVE_FILTER_VALUE = 'REMOVE_FILTER_VALUE';
export const SET_SORTING = 'SET_SORTING';
export const SEARCH = 'SEARCH';
export const RESET_SEARCH = 'RESET_SEARCH';
export const SET_LOADING = 'SET_LOADING';
export const SET_SEARCH_TERM = 'SET_SEARCH_TERM';
