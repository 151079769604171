import * as actionTypes from "../actionTypes";

const initialState = {
    loaded: false,

    allEstates: [],
    categories: [],
    locations: [],
    priceClasses: [],
    groups: []
};

export default (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.INITIALIZE_DATA: {
            return {
                ...state,
                loaded: true,
                categories: action.payload.categories,
                locations: action.payload.locations,
                priceClasses: action.payload.priceClasses,
                allEstates: action.payload.allEstates,
                groups: action.payload.groups,
            };
        }
        default: {
            return state;
        }
    }
};
