import React from 'react';
import PropTypes from 'prop-types';

const FormError = ({ error }) => {
    if (error === '') {
        return null;
    }

    return <div className="form-error">{error}</div>;
};

FormError.propTypes = {
    error: PropTypes.string,
};

FormError.defaultProps = {
    error: '',
};

export { FormError };
