import * as actionTypes from "./actionTypes";
import { getData } from "../utils/ajaxHelper";
import { pruneData } from "../utils/filters";

export const search = term => dispatch => {
    dispatch({
        type: actionTypes.SET_LOADING,
    });

    getData(`${window.baseUrl}/api/estates/${term}`).then( results => {
        dispatch({
            type: actionTypes.SEARCH,
            payload: {
                results: results
            }
        });
    });
};

export const resetSearch = () => dispatch => {
    dispatch({
        type: actionTypes.RESET_SEARCH
    });
};

export const setSearchTerm = searchTerm => dispatch => {
    dispatch({
        type: actionTypes.SET_SEARCH_TERM,
        payload: {
            searchTerm: searchTerm
        }
    });
};

export const setMinSurface = surface => dispatch => {
    dispatch({
        type: actionTypes.SET_SURFACE_MIN,
        payload: {
            value: surface,
        },
    });
};

export const setMaxSurface = surface => dispatch => {
    dispatch({
        type: actionTypes.SET_SURFACE_MAX,
        payload: {
            value: surface,
        },
    });
};

export const searchAndSetTerm = searchTerm => dispatch => {
    dispatch({
        type: actionTypes.SET_SEARCH_TERM,
        payload: {
            searchTerm: searchTerm
        }
    });

    if (searchTerm.length > 0) {
        dispatch({
            type: actionTypes.SET_LOADING,
        });

        getData(`${window.baseUrl}/api/estates/${searchTerm}`).then( results => {
            dispatch({
                type: actionTypes.SEARCH,
                payload: {
                    results: results
                }
            });
        });
    }
};

export const addFilterValue = (filter, value) => dispatch => {
    dispatch({
        type: actionTypes.ADD_FILTER_VALUE,
        payload: {
            filter: filter,
            value: value
        }
    });
};

export const setSorting = sorting => dispatch => {
    dispatch({
        type: actionTypes.SET_SORTING,
        payload: {
            sorting: sorting
        }
    })
};

export const removeFilterValue = (filter, value) => dispatch => {
    dispatch({
        type: actionTypes.REMOVE_FILTER_VALUE,
        payload: {
            filter: filter,
            value: value
        }
    });
};

export const requestData = () => dispatch => {
    Promise.all([
        getData(`${window.baseUrl}/api/estate-categories`),
        getData(`${window.baseUrl}/api/estate-price-classes`),
        getData(`${window.baseUrl}/api/estate-locations`),
        getData(`${window.baseUrl}/api/estates`),
        getData(`${window.baseUrl}/api/estate-groups`),
    ]).then( results => {
        dispatch({
            type: actionTypes.INITIALIZE_DATA,
            payload: pruneData(
                results[0],
                results[1],
                results[2],
                results[3],
                results[4],
            )
        });
    });
};
