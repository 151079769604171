import URI from "urijs";

/**
 * Removes empty query parameters from a querystring.
 */
export const removeEmptyParameters = data => {
    let result = {};

    Object.keys(data)
        .filter( key =>  Boolean(data[key]) && data[key].length )
        .forEach( key => result[key] = data[key] )

    return result;
};

/**
 * Turns an object into a querystring. Takes optional argument of 'many' to mark iterables.
 * 
 * @example
 * toQueryString({"first": 1, "second": "two", "third": 4});
 * // Returns ?first=1&second=two&third=4
 *
 * @example
 * toQueryString({"first": 1, "second": "two", "third": [4, 5, 6]}, ["third"]);
 * // Returns ?first=1&second=two&third=4,5,6
 */
export const toQueryString = (data, many = null) => {
    if (many != null)
        many.forEach( key => data[key] = data[key].join(",") );

    return URI("").search(data).query(removeEmptyParameters).toString();
}

export const parseQueryString = queryString => {
    // Remove '?', and split at '&'.
    const pairs = (queryString[0] === "?" ? queryString.substr(1) : queryString).split("&");

    // Map individual params to objects, and merge.
    return pairs.map( pair => {
        const split = pair.split("=");
        return { [decodeURIComponent(split[0])]: decodeURIComponent(split[1]) };
    }).reduce( (result, pair) => Object.assign(result, pair), {} );
};