import { createStore, compose, applyMiddleware } from "redux";
import createRootReducer from "./createRootReducer";
import * as actionTypes from "./actionTypes";
import * as actions from "./actions";
import thunk from "redux-thunk";
import ReduxQuerySync from "./middleware/querySyncMiddleware";

const composeEnhancers = (typeof window !== 'undefined' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) || compose;

/*
 * The queryStringEnhancer is a piece of Middleware for Redux that automatically synchonizes the url
 * querystring with the Redux store.
 *
 * If your querystring is '?categories=1&cities=2', it will set
 * filterValues.categories to an array: ["1"], and cities to ["2"]. Note two things: it turns the
 * values into strings, as querystring values are type-agnostic, and turns the values into an array.
 * To tell the middleware a value is an array you have to set 'multiple' to true (see below). Now a
 * querystring of '?categories=1&categories=2' will result in 'categories = ["1", "2"]', to show
 * that the user has selected two categories (apparments and houses for example).
 */
const queryStringEnhancer = ReduxQuerySync.enhancer({
    params: {
        searchTerm: {
            selector: state => state.filterValues.searchTerm,
            action: value => actions.searchAndSetTerm(value),
            multiple: false,
            defaultValue: ""
        },
        categories: {
            selector: state => state.filterValues.categories,
            action: value => ({ type: actionTypes.SET_FILTER_VALUE, payload: { filter: "categories", value: value } }),
            multiple: true,
            defaultValue: []
        },
        locations: {
            selector: state => state.filterValues.locations,
            action: value => ({ type: actionTypes.SET_FILTER_VALUE, payload: { filter: "locations", value: value } }),
            multiple: true,
            defaultValue: []
        },
        priceClasses: {
            selector: state => state.filterValues.priceClasses,
            action: value => ({ type: actionTypes.SET_FILTER_VALUE, payload: { filter: "priceClasses", value: value } }),
            multiple: true,
            defaultValue: []
        },
        groups: {
            selector: state => state.filterValues.groups,
            action: value => ({ type: actionTypes.SET_FILTER_VALUE, payload: { filter: "groups", value: value } }),
            multiple: true,
            defaultValue: []
        },
        surfaceMin: {
            selector: (state) => state.filterValues.surfaceMin,
            action: (value) => ({
                type: actionTypes.SET_FILTER_VALUE,
                payload: { filter: 'surfaceMin', value: value },
            }),
            multiple: false,
            defaultValue: '',
        },
        surfaceMax: {
            selector: (state) => state.filterValues.surfaceMax,
            action: (value) => ({
                type: actionTypes.SET_FILTER_VALUE,
                payload: { filter: 'surfaceMax', value: value },
            }),
            multiple: false,
            defaultValue: '',
        },
        minPrice: {
            selector: (state) => state.filterValues.minPrice,
            action: (value) => ({
                type: actionTypes.SET_FILTER_VALUE,
                payload: { filter: 'minPrice', value: value },
            }),
            multiple: false,
            defaultValue: '',
        },
        maxPrice: {
            selector: (state) => state.filterValues.maxPrice,
            action: (value) => ({
                type: actionTypes.SET_FILTER_VALUE,
                payload: { filter: 'maxPrice', value: value },
            }),
            multiple: false,
            defaultValue: '',
        },
    },
    replaceState: false,
    initialTruth: 'location'
});

export default () => createStore(
    createRootReducer(),
    composeEnhancers(
        queryStringEnhancer,
        applyMiddleware(thunk)
    )
);
