import React from 'react';
import PropTypes from 'prop-types';

import { Field } from 'react-final-form';

import {
    FormDescription,
    FormError, FormLabel,
} from '/components/forms/components';

import { CheckboxField } from '/components/forms/fields';

const CheckboxesField = ({ input, label, options, required, description, meta }) => (
    <div className={meta.touched && meta.error ? "form-item form-item--error" : "form-item"}>
        <FormLabel label={label} required={required} />
        <div className="checkboxes-group">
            {options.map((option) => (
                <Field
                    {...input}
                    component={CheckboxField}
                    label={option.label}
                    value={option.value}
                    key={option.value}
                />
            ))}
        </div>
        <FormDescription description={description} />
        {meta.touched && <FormError error={meta.error} />}
    </div>
);

CheckboxesField.propTypes = {
    input: PropTypes.shape({}).isRequired,
    required: PropTypes.bool,
    description: PropTypes.string,
    meta: PropTypes.shape({
        touched: PropTypes.bool,
        error: PropTypes.string,
    }).isRequired,
};

CheckboxesField.defaultProps = {
    description: '',
    required: false,
};

export { CheckboxesField };
