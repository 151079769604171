import React from 'react';

import { Form, Field } from 'react-final-form';
import { CheckboxField, SelectField, TextField, CheckboxesField, LocationsField } from '/components/forms/fields';

import { ValidationService } from '/services';

const EstateSearchForm = () => {
    const initialValues = {
        locations: [],
        subCategories: [],
    };

    const validate = (values) => {
        const rules = {
            purpose: 'required',
            firstName: 'required',
            lastName: 'required',
            email: 'required|email',
            mobile: 'required',
            privacy: 'required',
            subCategories: 'required',
            minPrice: 'required',
            maxPrice: 'required',
            locations: 'required',
            minBedrooms: 'required',
        };

        const names = {
            purpose: 'transactie',
            firstName: 'voornaam',
            lastName: 'achternaam',
            email: 'e-mailadres',
            mobile: 'telefoonnummer',
            subCategories: 'type',
            minPrice: 'minimum prijs',
            maxPrice: 'maximum prijs',
            locations: 'gemeentes',
            minBedrooms: 'minimum aantal slaapkamers',
        };

        return ValidationService.validate(values, rules, names);
    };

    const submit = async (values) => {
        const body = new FormData();
        body.append(window.csrfTokenName, window.csrfTokenValue);
        body.append('firstName', values.firstName);
        body.append('lastName', values.lastName);
        body.append('email', values.email);
        body.append('mobile', values.mobile);
        body.append('purpose', values.purpose && values.purpose.value);
        body.append('minPrice', values.minPrice);
        body.append('maxPrice', values.maxPrice);
        body.append('locations', values.locations.map(xxx => xxx.postalCode).join(','));
        body.append('bedrooms', values.minBedrooms);
        body.append('subCategories', values.subCategories.join(','));

        try {
            const response = await fetch('/index.php?p=actions/immodule/form/search', {
                method: 'post',
                body,
            });

            const bodyFromResponse = await response.json();

            if (bodyFromResponse.success) {
                window.location = window.successRedirect;
            } else {
                window.location = window.failureRedirect;
            }
        } catch {
            window.location = window.failureRedirect;
        }
    };

    const render = ({ handleSubmit }) => (
        <form className="estate-search-form" onSubmit={handleSubmit}>
            <div className="estimate-form-wrapper">
                <div className="estimate-form-rows">
                    <h2 className="estimate-form-title">
                        Naar wat bent u op zoek?
                    </h2>

                    <div className="freeform-rows">
                        <div className="freeform-row">
                            <div className="freeform-column">
                                <Field
                                    name="purpose"
                                    component={SelectField}
                                    label="Transactie"
                                    placeholder="Transactie*"
                                    options={window.purposeOptions}
                                    required
                                />
                            </div>
                            <div className="freeform-column">
                                <Field
                                    name="locations"
                                    component={LocationsField}
                                    label="Gemeentes"
                                    placeholder="Gemeentes*"
                                    required
                                />
                            </div>
                        </div>
                        <div className="freeform-row">
                            <div className="freeform-column">
                                <Field
                                    name="minPrice"
                                    component={TextField}
                                    label="Min. prijs"
                                    placeholder="Min. prijs"
                                    min="0"
                                    type="number"
                                    required
                                />
                            </div>
                            <div className="freeform-column">
                                <Field
                                    name="maxPrice"
                                    component={TextField}
                                    label="Max. prijs"
                                    placeholder="Max. prijs"
                                    type="number"
                                    min="1"
                                    required
                                />
                            </div>
                        </div>
                        <div className="freeform-row">
                            <div className="freeform-column">
                                <Field
                                    name="minBedrooms"
                                    component={TextField}
                                    label="Min. aantal slaapkamers"
                                    placeholder="Min. aantal slaapkamers"
                                    type="number"
                                    min="0"
                                    required
                                />
                            </div>
                            <div className="freeform-column"></div>
                        </div>
                        <div className="freeform-row">
                            <div className="freeform-column">
                                <Field
                                    name="subCategories"
                                    component={CheckboxesField}
                                    label="Type"
                                    options={window.categoryOptions}
                                    type="checkbox"
                                    required
                                />
                            </div>
                        </div>
                    </div>
                </div>

                <div className="estimate-form-rows">
                    <h2 className="estimate-form-title">
                        Naar wie mogen we de resultaten sturen?
                    </h2>

                    <div className="freeform-rows">
                        <div className="freeform-row">
                            <div className="freeform-column">
                                <Field
                                    name="firstName"
                                    component={TextField}
                                    label="Voornaam"
                                    placeholder="Voornaam"
                                    type="text"
                                    required
                                />
                            </div>
                            <div className="freeform-column">
                                <Field
                                    name="lastName"
                                    component={TextField}
                                    label="Naam"
                                    placeholder="Naam"
                                    type="text"
                                    required
                                />
                            </div>
                        </div>
                        <div className="freeform-row">
                            <div className="freeform-column">
                                <Field
                                    name="email"
                                    component={TextField}
                                    label="E-mail"
                                    placeholder="E-mail"
                                    type="email"
                                    required
                                />
                            </div>
                            <div className="freeform-column">
                                <Field
                                    name="mobile"
                                    component={TextField}
                                    label="Telefoon"
                                    placeholder="Telefoon"
                                    type="tel"
                                    required
                                />
                            </div>
                        </div>
                        <div className="freeform-row">
                            <div className="freeform-column">
                                <Field
                                    name="privacy"
                                    component={CheckboxField}
                                    label={window.privacyMessage}
                                    type="checkbox"
                                    required
                                />
                            </div>
                        </div>
                        <div className="freeform-row">
                            <div className="freeform-column">
                                <div className="freeform-button-wrapper">
                                    <button className="button" type="submit">
                                        Hou me op de hoogte
                                        <span className="button-icon">
                                            <svg className="ml-1 w-5 h-5 fill-current" viewBox="0 0 12 21" xmlns="http://www.w3.org/2000/svg"><path d="m147.271262 17.6666667 6.681546 9.9802321-6.681546 10.0197679h4.873741l6.640735-10.0197679-6.640735-9.9802321z" fill-rule="evenodd" transform="translate(-147 -17)"></path></svg>
                                        </span>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    );

    return (
        <Form
            initialValues={initialValues}
            validate={validate}
            onSubmit={submit}
            render={render}
        />
    );
};

export { EstateSearchForm };
