import history from '../../utils/history';
import URI from "urijs";

const ReduxQuerySync = ({ store, params }) => {
    const { dispatch } = store;
    let ignoreLocationUpdate = false;
    let lastQueryValues;

    const getQueryValues = ({ search }) => {
        const locationParams = URI.parseQuery(search);
        const queryValues = {};

        Object.keys(params).forEach( param => {
            const { defaultValue, stringToValue = s => s } = params[param];
            const valueString = locationParams[param];
            const value = valueString ? stringToValue(valueString) : defaultValue;
            queryValues[param] = value;
        });

        return queryValues;
    };

    const handleLocationUpdate = (location) => {
        if (ignoreLocationUpdate)
            return;
        
        const state = store.getState();
        const queryValues = getQueryValues(location);
        const actionsToDispatch = [];

        Object.keys(queryValues).forEach(param => {
            let value = queryValues[param];

            if (lastQueryValues === undefined || lastQueryValues[param] !== value) {
                const { selector, action, multiple } = params[param];

                if (multiple && !Array.isArray(value))
                    value = [value];

                if (selector(state) !== value)
                    actionsToDispatch.push(action(value));
            }
        });

        actionsToDispatch.forEach( action => dispatch(action) );
    };

    // Sync store to location on every location change.
    const unsubscribeFromLocation = history.listen(handleLocationUpdate);

    // Sync store to location now.
    handleLocationUpdate(history.location);

    return () => {
        unsubscribeFromLocation();
    };
};

ReduxQuerySync.enhancer = config => storeCreator => (reducer, initialState, enhancer) => {
    // Create the store as usual.
    const store = storeCreator(reducer, initialState, enhancer)

    // Hook up our listeners.
    ReduxQuerySync({store, ...config})

    return store
};

export default ReduxQuerySync