class LocalStorageService {
    static set = (key, value) => {
        try {
            window.localStorage.setItem(key, JSON.stringify(value));
        } catch (error) {
            // progressive
        }
    };

    static get = (key) => {
        const string = window.localStorage.getItem(key);

        if (string) {
            return JSON.parse(string);
        }

        return null;
    };
}

export { LocalStorageService };
