class PriceFormattingService {
    static format = (price) => {
        if (Boolean(price) === false) {
            return '';
        }

        return price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.');
    };
}

export { PriceFormattingService };
